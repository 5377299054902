import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import checkIcon from 'browser/assets/icons/check.svg';
import PropTypes from 'prop-types';

const TERMS = {
  pl: [
    {
      formTermsText:
        'Wyrażam zgodę na przetwarzanie moich danych osobowych w celu obsługi zapytania lub przedstawienia oferty. Wyrażenie zgody jest dobrowolne, ale konieczne, abyśmy mogli kontaktować się z Państwem w celu obsługi zapytania i przedstawienia oferty. Jeżeli nie chcą Państwo, abyśmy kontaktowali się w tym celu za pomocą e-maila lub telefonu, zapraszamy do odwiedzenia najbliższego biura sprzedaży.*',
    },
    {
      formTermsText:
        'Wyrażam zgodę na wykorzystywanie przez Matexi Polska sp. z o.o. oraz inne podmioty z grupy kapitałowej Matexi Polska sp. z o.o. telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących tj. telefon, poczta e-mail dla celów marketingowych w rozumieniu przepisów ustawy z dnia 16 lipca 2014 r. Prawo telekomunikacyjne.',
    },
    {
      formTermsText:
        'Wyrażam zgodę na przetwarzanie moich danych osobowych przez Matexi Polska sp. z o.o. oraz inne podmioty z grupy kapitałowej Matexi Polska sp. z o.o. w celach marketingowych w tym m.in. dla informowania o aktualnej ofercie.',
    },
    {
      formTermsText:
        'Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji handlowych od Matexi Polska sp. z o.o. oraz innych podmiotów z grupy kapitałowej Matexi Polska sp. z o.o. w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną o treści marketingowej.',
    },
  ],
  en: [
    {
      formTermsText:
        'I give consent to the processing of my personal data for the purposes of my inquiry or presentation of the offer. The consent is voluntary, but required to allow us to contact you to respond to your inquiry and present our offer. If you do not wish to be contacted by e-mail or telephone, please visit our nearest sales office.*',
    },
    {
      formTermsText:
        'I hereby give consent to Matexi Polska sp. z o.o. to use telecommunication terminal devices and automated dialing systems, i.e. telephone and email, for marketing purposes in the meaning of the Act of 16 July 2014 - Telecommunications',
    },
    {
      formTermsText:
        'I hereby give consent to Matexi Polska sp. z o.o. to process my personal data for marketing purposes, including for the purpose of informing me about the current offer.',
    },
    {
      formTermsText:
        'I hereby give consent to receive trade information from Matexi Polska sp. z o.o. in the meaning of the Act of 18 July 2002 on the provision of marketing services by electronic means.',
    },
  ],
};

export const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin: 10px 0;
  cursor: pointer;
  font-size: 1.2rem;
  text-align: left;
  &:first-of-type {
    margin-bottom: 13px;
    & > p {
      padding-top: 3px;
    }
  }
`;

const LabelBtn = styled.button`
  background: transparent;
  color: #2b953e;
  font-family: Inter, sans-serif;
  cursor: pointer;
  align-self: flex-start;
  padding: 2px 0 4px 10px;
  margin-left: auto;
`;

const LabelText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
      padding-top: 2px;
      word-break: normal;
    `}
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ big }) => (big ? '25px' : '20px')};
  width: ${({ big }) => (big ? '25px' : '20px')};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid;
  transition: 0.2s;
  border-color: ${({ theme, error }) => (error ? '#DD4363' : '#CFCFCF')};
  &:after {
    content: '';
    position: absolute;
    opacity: 0;
    transition: 0.2s;
    left: ${({ big }) => (big ? '6px' : '3px')};
    top: ${({ big }) => (big ? '5px' : '3px')};
    width: 12px;
    height: 12px;
    background: url(${checkIcon}) no-repeat center;
  }
`;

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ ${Checkmark} {
    background: #f4f8de;
    border-color: #f4f8de;
    &:after {
      opacity: 1;
    }
  }
`;

const StyledLabelText = styled(LabelText)`
  font-weight: ${({ theme }) => theme.bold};
`;

const Terms = ({ setFieldValue, values, setErrors, errors, submitClicked }) => {
  const finalTerms = TERMS.pl.map(({ formTermsText }, index) => ({
    name: `agree${index + 1}Browser`,
    content: formTermsText,
    required: index === 0,
  }));

  const [expandedTerms, setExpandedTerms] = useState([]);

  const handleCheckbox = name => {
    if (expandedTerms.includes(name)) {
      setExpandedTerms(expandedTerms.filter(term => term !== name));
    } else setExpandedTerms([...expandedTerms, name]);
  };

  const acceptAllKey = 'agreeAllBrowser';
  const agree1Key = 'agree1Browser';

  return (
    <>
      <Label htmlFor={acceptAllKey}>
        <StyledLabelText>Zaznacz wszystkie zgody</StyledLabelText>
        <Checkbox
          id={acceptAllKey}
          name={acceptAllKey}
          type="checkbox"
          onChange={() => {
            if (!finalTerms.every(term => values[term.name] === true)) {
              setFieldValue(acceptAllKey, true);
              finalTerms.forEach(term => setFieldValue(term.name, true));
            } else {
              setFieldValue(acceptAllKey, true);
              finalTerms.forEach(term => setFieldValue(term.name, false));
            }
          }}
          checked={finalTerms.every(term => values[term.name] === true)}
        />
        <Checkmark />
      </Label>
      {finalTerms.map(({ name, content, required }) => (
        <Label htmlFor={name} key={name}>
          <LabelText expanded={expandedTerms.includes(name)}>
            {content}
          </LabelText>
          <LabelBtn type="button" onClick={() => handleCheckbox(name)}>
            {expandedTerms.includes(name) ? 'Mniej' : 'Więcej'}
          </LabelBtn>
          <Checkbox
            id={name}
            type="checkbox"
            onChange={() => {
              setFieldValue(name, !values[name]);
              required && setErrors({ ...errors, [agree1Key]: '' });
            }}
            checked={values[name]}
          />
          <Checkmark
            error={
              submitClicked &&
              errors[agree1Key] &&
              required &&
              values[name] === false
            }
          />
        </Label>
      ))}
    </>
  );
};

Terms.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  submitClicked: PropTypes.bool.isRequired,
};

export default Terms;
