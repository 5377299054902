/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  background: ${({ theme, secondary }) =>
    secondary ? 'transparent' : '#2b953e'};
  color: ${({ theme }) => theme.colors.white};
  font-family: Inter, sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  border: 2px solid #2b953e;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  float: left;
  clear: left;
  box-shadow: 0 1px 30px -5px rgba(0, 0, 0, 0.2);
  height: 45px;
  @media (min-width: 440px) {
    height: 55px;
    padding: 16px 30px;
    font-size: 1.4rem;
  }
  ${({ noHover }) =>
    !noHover &&
    css`
      @media (hover: hover) {
        &:hover {
          background: ${({ theme, secondary }) =>
            secondary ? '#2b953e' : 'transparent'};
          color: #2b953e;
          ${({ primaryLightHover }) =>
            primaryLightHover &&
            css`
              background: #f4f8de;
              border-color: #f4f8de;
              color: #272727;
            `}
        }
      }
    `}
  ${({ outlinePrimaryDark }) =>
    outlinePrimaryDark &&
    css`
      box-shadow: none;
      border: 2px solid #2b953e;
      background: transparent;
      color: #272727;
      ${({ noHover }) =>
        !noHover &&
        css`
          @media (hover: hover) {
            &:hover {
              background: #2b953e;
              color: ${({ theme }) => theme.colors.white};
            }
          }
        `}
    `}
  ${({ submit }) =>
    submit &&
    css`
      position: relative;
      ${({ noHover }) =>
        !noHover &&
        css`
          @media (hover: hover) {
            &:hover {
              color: #2b953e;
              border-color: #2b953e;
            }
          }
        `}
      pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
        border: 3px solid ${({ theme }) => theme.white};
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-radius: 50%;
        opacity: ${({ disabled }) => (disabled ? '1' : '0')};
        animation: ${spin} 1s ease infinite;
      }
    `}
`;

export default Button;

const ViewButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 15px;
  width: 100%;
  max-width: 190px;
  height: 72px;
  border-radius: 30px;
  transition: 0.3s;
  background: transparent;
  cursor: pointer;
  @media (min-width: 576px) {
    padding: 10px 30px;
  }

  &:hover {
    background: ${({ theme, active }) => (active ? '#f4f8de' : '#F7F7F7')};
  }
  svg {
    fill: #888888;
    transition: 0.3s;
  }

  ${({ active }) =>
    active &&
    css`
      background: #f4f8de;
    `}

  ${({ active, noHover }) =>
    active &&
    noHover &&
    css`
      background: transparent;
      .c {
        stroke: #2b953e !important;
      }
      .d {
        fill: #2b953e !important;
      }
    `}

  span {
    font-size: 1.4rem;
    white-space: nowrap;
    margin-left: 9px;
    @media (min-width: 576px) {
      margin-left: 15px;
      font-size: 1.6rem;
    }
  }
  .d {
    fill: ${({ theme, active }) => (active ? '#2b953e' : '#888')};
    transition: 0.3s;
  }
  .a,
  .c {
    fill: none;
  }
  .a {
    stroke: ${({ theme, active }) => (active ? '#2b953e' : '#888')};
    transition: 0.3s;
    stroke-width: 2px;
  }
  .b {
    stroke: none;
  }

  ${({ noHover }) =>
    noHover &&
    css`
      background: transparent !important;
      &:hover {
        background: transparent !important;
      }

      .d {
        fill: #bbb;
      }
      .a {
        stroke: #bbb;
      }
    `}
`;

export const ViewButton = ({ children, list, ...props }) => (
  <ViewButtonWrapper {...props}>
    {list ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="22"
        viewBox="0 0 35 22"
      >
        <rect className="d" width="4" height="4" />
        <rect className="d" width="4" height="4" transform="translate(0 9)" />
        <rect className="d" width="4" height="4" transform="translate(0 18)" />
        <rect className="d" width="27" height="4" transform="translate(8)" />
        <rect className="d" width="27" height="4" transform="translate(8 9)" />
        <rect className="d" width="27" height="4" transform="translate(8 18)" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="34"
        viewBox="0 0 35 34"
      >
        <g className="a">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
        <g className="a" transform="translate(0 19)">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
        <g className="a" transform="translate(20)">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
        <g className="a" transform="translate(20 19)">
          <rect className="b" width="15" height="15" />
          <rect className="c" x="1" y="1" width="13" height="13" />
        </g>
      </svg>
    )}

    <span>{children}</span>
  </ViewButtonWrapper>
);
