/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from 'browser/components/Button';
import Input from 'browser/components/Form/Input';
import Terms from 'browser/components/Form/Terms';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import useOutsideClick from 'browser/hooks/useOutsideClick';
import getFormParams from 'browser/utils/getFormParams';
import getCookie from 'browser/utils/getCookie';
import getYLFormParams from 'utils/getYLFormParams';
import SuccessInfo from 'components/shared/SuccessInfo';
import emitMatomoEvent, {
  eventsNames,
  getVisitorId,
} from 'utils/emitMatomoEvent';

const MAILER_URL =
  'https://matexi.indigital.guru/kameralnyprokocim2/mailer/mailer-main.php';

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    `}
`;

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px 30px 5px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 45px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s cubic-bezier(0.5, 0.32, 0.11, 1);
  transform: scale(0.9);
  max-height: 620px;
  overflow-y: auto;
  @media (max-width: 439px) {
    max-width: 100vw;
    padding: 27px 15px 5px;
    transform: translateX(100vw);
    height: 100%;
    transition: 0.4s cubic-bezier(0.5, 0.32, 0.11, 1);
    max-height: none;
  }
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: scale(1) translateX(0) !important;
      will-change: transform;
    `};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin-bottom: 4px;
  }
`;

const Heading = styled.h3`
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 25px;
  @media (max-width: 360px) {
    font-size: 2.2rem;
  }
`;

const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  label {
    :first-of-type {
      margin: 10px 0 4px;
    }
    margin: 2px 0;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding-top: 25px;
  button {
    width: 100%;
    box-shadow: none;
    margin-bottom: 25px;
    @supports (-webkit-touch-callout: none) {
      @media (max-width: 440px) {
        margin-bottom: 140px;
      }
    }
  }
`;

const ErrorTerms = styled.p`
  margin: 8px 0 -10px;
  text-align: left;
  color: #dd4363;
  font-size: 1.4rem;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  transition: 0.3s;
  background: #f5f5f5;
  padding: 12px;
  @media (max-width: 360px) {
    right: 15px;
  }
  svg {
    width: 100%;
    height: 100%;
    transition: 0.3s;
  }
  &:hover {
    background: #2b953e;
    svg {
      fill: #fff;
    }
  }
`;

const Message = styled.p`
  margin: 12px 0 -8px;
  text-align: left;
  color: ${({ theme, error }) => (error ? '#DD4363' : '#2b953e')};
`;

const SuccessInfoWrapper = styled.aside`
  max-width: 400px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  box-shadow: 2px 2px 45px rgb(0 0 0 / 15%);
  border-radius: 4px;

  button {
    background-color: #56b947;
    color: ${({ theme }) => theme.colors.white};

    &:before {
      border-left-color: #d1de4d;
    }
    &:after {
      border-left-color: #2b953e;
    }

    &:hover {
      background-color: #81c340;
    }
  }
`;

// const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;

const FlatForm = ({
  heading,
  flat,
  investment,
  isVisible,
  closeForm,
  bookFlat,
  flatID,
  favourites,
}) => {
  const [formMessage, setFormMessage] = useState({ text: '', error: false });
  const [shouldRender, setShouldRender] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [fillEventStarted, setFillEventStarted] = useState(false);
  const [fillEventEmitted, setFillEventEmitted] = useState(false);
  const formikRef = useRef(null);

  const contactSchema = Yup.object().shape({
    name: Yup.string().required('Proszę o podanie imienia'),
    // surname: Yup.string().required('Proszę o podanie nazwiska'),
    email: Yup.string()
      .email('Adres email jest nieprawidłowy')
      .required('Proszę podać adres e-mail'),
    phone: Yup.string()
      .min(9, 'Numer telefonu jest nieprawidłowy')
      .matches(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        'Numer telefonu jest nieprawidłowy'
      )
      .required('Proszę podać numer telefonu'),
    // message: Yup.string().required('messageErrorReq'),
    agree1Browser: Yup.bool().oneOf([true], 'Proszę o zaznaczenie zgód'),
  });

  const contactInitialValues = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    message: '',
    agree1Browser: false,
    agree2Browser: false,
    agree3Browser: false,
    agree4Browser: false,
  };

  useEffect(() => {
    if (fillEventStarted && fillEventEmitted) {
      emitMatomoEvent({
        event: bookFlat
          ? eventsNames.formularz_rezerwacja_wypelnianie_start
          : eventsNames.formularz_mieszkanie_wypelnianie_start,
        flat_id: flatID,
      });
    }
  }, [fillEventStarted, fillEventEmitted]);

  useEffect(() => {
    setFillEventStarted(false);
    setFillEventEmitted(false);
  }, []);

  useEffect(() => {
    if (isVisible) setShouldRender(true);
    else setTimeout(() => setShouldRender(false), 500);
  }, [isVisible]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm();
      const flatInvestmentMessage = {
        pl: `Dzień dobry, interesuje mnie mieszkanie nr ${flat}. Proszę o kontakt.`,
        en: `Hello, I'm interested in apartment no. ${flat}. Please contact me.`,
      };
      const flatMessage = {
        pl: `Jestem zainteresowany mieszkaniami o numerach: ${flat}.`,
        en: `I'm interested in apartments with numbers: ${flat}.`,
      };
      const flatBookMessage = {
        pl: `Proszę o rezerwację mieszkania ${flat}`,
        en: `Please reserve an apartment ${flat}`,
      };
      if (flat && investment) {
        formikRef.current.setFieldValue(
          'message',
          bookFlat ? flatBookMessage.pl : flatInvestmentMessage.pl
        );
      } else if (flat) {
        formikRef.current.setFieldValue('message', flatMessage.pl);
      } else {
        formikRef.current.setFieldValue('message', ``);
      }
    }
  }, [flat, investment, bookFlat]);

  const modalRef = useRef(null);
  useOutsideClick(modalRef, closeForm, null, isVisible);

  const handleReset = () => {
    setSubmitSuccess(false);
    setSubmitClicked(false);
    setFormMessage({ text: '', color: '' });
    closeForm();
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={contactInitialValues}
      validationSchema={contactSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const throwError = message => {
          setSubmitting(false);
          setFormMessage({
            text: message || 'Coś poszło nie tak!',
            error: true,
          });

          setTimeout(() => {
            setFormMessage({ text: '', error: false });
          }, 2500);
        };
        (async () => {
          const {
            name,
            surname,
            email,
            phone,
            message,
            agree1Browser: agree1,
            agree2Browser: agree2,
            agree3Browser: agree3,
            agree4Browser: agree4,
          } = values;

          let params = {
            name,
            surname,
            agree1,
            agree2,
            agree3,
            agree4,
            source: bookFlat
              ? `Wyszukiwarka - REZERWACJA ${flat}`
              : `Wyszukiwarka - ${flat}`,
            url: window.location.href,
          };
          email ? (params = { ...params, email }) : params;
          phone ? (params = { ...params, phone }) : params;
          message ? (params = { ...params, message }) : params;
          if (agree1 === false) return setSubmitting(false);
          try {
            setSubmitting(true);

            const formData = new FormData();
            formData.set('name', `${name} ${surname}`);
            formData.set('email', email);
            formData.set('phone', phone);
            formData.set('message', message);
            formData.set(
              'formId',
              bookFlat ? 'form-browser-reservation' : 'form-browser'
            );
            formData.set('flat', flat);
            formData.set('consent1', true);
            formData.set('consent2', true);
            formData.set('consent3', true);
            formData.set('consent4', true);
            formData.set('url', window.location.href);

            const { data } = await axios.post(MAILER_URL, formData);

            if (data.status === 'error') {
              throw new Error('error');
            }

            const formID = bookFlat ? `reserve-form` : `contact-form-flat`;
            const YLFormParams = getYLFormParams({ ...params, investment });

            if (window.Monitor)
              window.Monitor.TrackForm(formID, 3, 3, YLFormParams);

            params = flatID ? { ...params, flatID } : params;
            params = investment ? { ...params, investment } : params;

            const formName = favourites
              ? 'lp-form-ulubione'
              : 'lp-form-mieszkanie';
            const formEvent = favourites ? 'formularz_ulubione' : 'formularz';

            const CRMFormParams = {
              ...getFormParams(params),
              leadID: data.leadId,
              visitorID: getCookie('m_vis_id'),
              form_name: bookFlat ? 'lp-form-rezerwacja' : formName,
              set_reservation: bookFlat ? 1 : 0,
            };

            if (formName === 'lp-form-ulubione') {
              delete CRMFormParams.id_product;
            }

            const { data: CRMData } = await axios.post(
              `https://api.matexipolska.pl/export/api_makeform/?mode=json`,
              {
                ...CRMFormParams,
              }
            );

            if (bookFlat && CRMData.response.ReservationStatus === 0) {
              throwError(
                `Nie udało się zarezerwować mieszkania. Spróbuj ponownie później.`
              );
              return;
            }

            if (window.dataLayer) {
              window.dataLayer.push({
                event: bookFlat ? 'formularz_rezerwacja' : formEvent,
                mieszkanie: flat || '',
              });
              if (bookFlat) {
                window.dataLayer.push({
                  event: 'Rezerwacja',
                  mieszkanie: flat || '',
                });
              }
            }
            if (window._mtm) {
              window._mtm.push({
                event: bookFlat ? 'formularz_rezerwacja' : formEvent,
                mieszkanie: flat || '',
              });
              if (bookFlat) {
                window._mtm.push({
                  event: 'Rezerwacja',
                  mieszkanie: flat || '',
                });
              }
            }

            emitMatomoEvent({
              event: bookFlat
                ? eventsNames.formularz_rezerwacja_wyslany
                : eventsNames.formularz_mieszkanie_wyslany,
              flat_id: flatID,
              lead_id: data.leadid,
            });

            setSubmitting(false);
            setFormMessage({
              text: bookFlat
                ? `W celu potwierdzenia rezerwacji prosimy o kliknięcie w link, który przesłaliśmy na podany w formularzu adres e-mail. Link będzie aktywny przez 15 minut, po tym czasie możliwość dokonania rezerwacji zostanie anulowana. Rezerwacja będzie aktywna maksymalnie przez 3 dni.`
                : data.message,
              error: false,
            });
            setSubmitSuccess(true);

            setTimeout(() => {
              handleReset();
              resetForm();
            }, 15000);
          } catch (err) {
            console.log(err);
            emitMatomoEvent({
              event: bookFlat
                ? eventsNames.formularz_rezerwacja_blad_wysylki
                : eventsNames.formularz_mieszkanie_blad_wysylki,
              flat_id: flatID,
            });
            throwError();
          }
        })();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setErrors,
        isSubmitting,
        resetForm,
      }) => (
        <Overlay
          className={isVisible ? 'form-overlay-visible' : ''}
          isVisible={isVisible}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {(shouldRender || isVisible) &&
            (submitSuccess ? (
              <SuccessInfoWrapper>
                <SuccessInfo
                  onClick={() => {
                    handleReset();
                    resetForm();
                  }}
                  reserveMessage={bookFlat}
                />
              </SuccessInfoWrapper>
            ) : (
              <FormWrapper isVisible={isVisible} ref={modalRef}>
                <CloseButton onClick={closeForm}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                  >
                    <path
                      d="M12.215,11l9.533-9.533A.859.859,0,1,0,20.533.252L11,9.785,1.467.252A.859.859,0,0,0,.251,1.468L9.784,11,.251,20.534a.859.859,0,0,0,1.215,1.215L11,12.216l9.533,9.533a.859.859,0,0,0,1.215-1.215Z"
                      transform="translate(0 -0.001)"
                    />
                  </svg>
                </CloseButton>
                <Heading>{heading}</Heading>
                <Form
                  id="flat-form"
                  name="flat-form"
                  onSubmit={formValues => {
                    handleSubmit(formValues);
                    setSubmitClicked(true);
                  }}
                >
                  <Column>
                    <Input
                      id="name"
                      label="Imię"
                      required
                      labelAsPlaceholder
                      onChangeFn={handleChange}
                      onKeyDownFn={() => {
                        if (!fillEventStarted && !fillEventEmitted) {
                          setFillEventStarted(true);
                          setFillEventEmitted(true);
                        }
                      }}
                      onBlurFn={handleBlur}
                      value={values.name}
                      touched={touched.name}
                      errors={submitClicked && errors.name}
                    />
                    <Input
                      id="surname"
                      label="Nazwisko"
                      // required
                      labelAsPlaceholder
                      onChangeFn={handleChange}
                      onKeyDownFn={() => {
                        if (!fillEventStarted && !fillEventEmitted) {
                          setFillEventStarted(true);
                          setFillEventEmitted(true);
                        }
                      }}
                      onBlurFn={handleBlur}
                      value={values.surname}
                      touched={touched.surname}
                      errors={submitClicked && errors.surname}
                    />
                    <Input
                      id="email"
                      label="Adres e-mail"
                      required
                      labelAsPlaceholder
                      onChangeFn={handleChange}
                      onKeyDownFn={() => {
                        if (!fillEventStarted && !fillEventEmitted) {
                          setFillEventStarted(true);
                          setFillEventEmitted(true);
                        }
                      }}
                      onBlurFn={handleBlur}
                      value={values.email}
                      touched={touched.email}
                      errors={submitClicked && errors.email}
                    />
                    <Input
                      id="phone"
                      label="Numer telefonu"
                      required
                      labelAsPlaceholder
                      onChangeFn={handleChange}
                      onKeyDownFn={() => {
                        if (!fillEventStarted && !fillEventEmitted) {
                          setFillEventStarted(true);
                          setFillEventEmitted(true);
                        }
                      }}
                      onBlurFn={handleBlur}
                      value={values.phone}
                      touched={touched.phone}
                      errors={submitClicked && errors.phone}
                    />
                  </Column>
                  <Input
                    textarea
                    // required
                    labelAsPlaceholder
                    id="message"
                    label="Wiadomość"
                    onChangeFn={handleChange}
                    onKeyDownFn={() => {
                      if (!fillEventStarted && !fillEventEmitted) {
                        setFillEventStarted(true);
                        setFillEventEmitted(true);
                      }
                    }}
                    onBlurFn={handleBlur}
                    value={values.message}
                    touched={touched.message}
                    errors={submitClicked && errors.message}
                  />
                  <TermsWrapper>
                    <Terms
                      setFieldValue={setFieldValue}
                      values={values}
                      setErrors={setErrors}
                      errors={errors}
                      submitClicked={submitClicked}
                    />
                  </TermsWrapper>
                  {submitClicked && errors.agree1Browser && (
                    <ErrorTerms>{errors.agree1Browser}</ErrorTerms>
                  )}
                  {formMessage.text && (
                    <Message error={formMessage.error}>
                      {formMessage.text}
                    </Message>
                  )}
                  <ButtonWrapper>
                    <Button
                      submit
                      type="submit"
                      disabled={isSubmitting}
                      isSubmitting={isSubmitting}
                    >
                      {!isSubmitting && 'Wyślij wiadomość'}
                    </Button>
                  </ButtonWrapper>
                </Form>
              </FormWrapper>
            ))}
        </Overlay>
      )}
    </Formik>
  );
};

FlatForm.propTypes = {
  heading: PropTypes.string.isRequired,
  flat: PropTypes.string.isRequired,
  flatID: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  closeForm: PropTypes.func.isRequired,
  investment: PropTypes.string,
  bookFlat: PropTypes.bool,
  favourites: PropTypes.bool,
};

FlatForm.defaultProps = {
  investment: '',
  bookFlat: false,
  favourites: false,
};

export default FlatForm;
